import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse, ITrainingLocation } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'trainingLocations'

const getAll = (axios: IFactoryFunctionRequest) => ({ firmId }: { firmId: string }): IFactoryResponse<Array<ITrainingLocation>> => {
  console.log('firmId', firmId)
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/${firmId}`,
  })
}

const addOne = (axios: IFactoryFunctionRequest) => (trainingLocation: Omit<ITrainingLocation, '_id' | 'isDeleted' | 'modifiedBy'>): IFactoryResponse<ITrainingLocation> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}`,
    data: trainingLocation,
  })
}

export default (axios: AxiosInstance) => ({
  getAll: getAll(useAxios(axios)),
  addOne: addOne(useAxios(axios)),
})
