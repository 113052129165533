import type { RouteRecordRaw } from 'vue-router'

const MODULE_NAME = 'MANAGEMENT'
const MODULE_ID = undefined

const managementRoutes: Array<RouteRecordRaw> = [
  {
    path: '/management',
    meta: {
      requiresAuth: true,
      requiresManagement: true,
    },
    beforeEnter: [],
    children: [
      {
        path: 'account',
        name: 'ManagementAccounts',
        component: () => import('@/views/management/Account.vue'),
        meta: {
          requiresAuth: true,
        },
        beforeEnter: [],
      },
      {
        path: 'pricing',
        name: 'ManagementPricing',
        component: () => import('@/views/management/Pricing.vue'),
        meta: {
          requiresAuth: true,
        },
        beforeEnter: [],
      },
      {
        path: 'account/:clientId',
        name: 'ManagementAccount',
        component: () => import('@/views/management/ClientAccount.vue'),
        meta: {
          requiresAuth: true,
        },
        beforeEnter: [],
      },
      {
        path: 'servermanagement',
        name: 'ManagementServers',
        component: () => import('@/views/management/ServerManagement.vue'),
        meta: {
          requiresAuth: true,
        },
        beforeEnter: [],
      },

    ],

  },
]

export default managementRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }))
