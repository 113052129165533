import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'recruitmentMailTemplates'

const getAll = (axios: IFactoryFunctionRequest) => () => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm`,
  })
}

const getOne = (axios: IFactoryFunctionRequest) => ({ _id }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${_id}`,
  })
}

const filter = (axios: IFactoryFunctionRequest) => ({ page = 0, search = '', limit = 10 }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/filter`,
    query: {
      page,
      search,
      limit,
    },
  })
}

const retreiveOneCreateMail = (axios: IFactoryFunctionRequest) => ({ _id }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/createMail/${_id}`,
  })
}

export default (axios: AxiosInstance) => ({
  getAll: getAll(useAxios(axios)),
  getOne: getOne(useAxios(axios)),
  filter: filter(useAxios(axios)),
  retreiveOneCreateMail: retreiveOneCreateMail(useAxios(axios)),
})
