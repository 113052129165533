import { useUserStore } from '@/config/store/userStore'

export const canUserAccessModule = (user = useUserStore().user) => {
  return user.profile.isSubAdminProfile
}

export const guardUsers = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return canUserAccessModule(user) ? user.profile.modules.globalSettings.userAccess : { name: 'Home' }
}

export const guardProfiles = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return canUserAccessModule(user) ? user.profile.modules.globalSettings.userAccess && user.profile.modules.globalSettings.profileAccess : { name: 'Home' }
}
