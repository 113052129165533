import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'expenses'

type optionsExpenses = {
  _firmId: string;
  _id: string;
};

const getExpensesByProject = (axios: IFactoryFunctionRequest<optionsExpenses, undefined>) => (projectId: string, data: any) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${projectId}`,
    data
  })
}

export default (axios: AxiosInstance) => ({
  getExpensesByProject: getExpensesByProject(useAxios(axios))
})
