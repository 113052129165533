import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'recruitmentRejectionCategorys'

const getAll = (axios: IFactoryFunctionRequest) => ({ firmId }: { firmId?: string }): IFactoryResponse<Array<unknown>> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/all/filter`,
  })
}
// const addOne = (axios: IFactoryFunctionRequest) => (trainer:  Omit<ITrainingTrainer, '_id'>): IFactoryResponse<ITrainingTrainer> => {
//     return axios({
//         method: EMethod.post,
//         url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}`,
//         data: trainer
//     })
// }

export default (axios: AxiosInstance) => ({
  getAll: getAll(useAxios(axios)),
  // addOne: addOne(useAxios(axios))
})
