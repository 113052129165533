import type { Router } from 'vue-router'
import { useHead } from '@unhead/vue'
import i18n from '@/config/translation'
import { useUserStore } from '@/config/store/userStore'
import { useApplicationStore } from '@/config/store/applicationStore'

/**
 * Take a router and add navigation guard for the app
 */
export default (router: Router) => {
  router.beforeEach((to) => {
    const userStore = useUserStore()
    const user = userStore.user
    const routeName = to.name
    const isOnLogin = routeName === 'Login'
    const isOnCandidateOnboarding = routeName === 'CandidateOnboarding'
    const isAuthRequired = to.meta.requiresAuth
    const isManagementRequired = to.meta.requiresManagement
    const isCandidateProfile = userStore.user?.profile?.isCandidateProfile
    if (routeName === 'Disconnection') {
      userStore.disconnection()
      return { name: 'Login' }
    }
    if (!isAuthRequired)
      return true

    if (!isOnCandidateOnboarding && isCandidateProfile)
      return { name: 'CandidateOnboarding' }
    if (isOnLogin && user)
      return { name: 'Home' }
    if ((!user && !isOnLogin)) {
      const applicationStore = useApplicationStore()

      applicationStore.previousApplicationRoute = to

      return { name: 'Login' }
    }

    if (!user && isOnLogin)
      return true
    if (!userStore.user.profile) {
      userStore.disconnection()
      return false
    }

    if (isManagementRequired && userStore.user.role !== 'admin')
      return { name: 'Home' }

    return true
  })

  router.afterEach((to, from, failure) => {
    const title = to.meta.titleTranslationKey
    if (typeof title !== 'string' || failure) {
      useHead({
        title: 'BestConsultant',
      })
      return
    }

    const translation = i18n.global.t(title)
    useHead({
      title: `${translation} - BestConsultant`,
    })
  })

  return router
}
