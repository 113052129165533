import type { AxiosInstance } from 'axios'
import type {
  IFactoryFunctionRequest,
} from '@bc-library/interface'
import {
  EMethod,
} from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'documents'

const deleteOne
	= (axios: IFactoryFunctionRequest<any, typeof document>) =>
	  (document: any) => {
	    return axios({
	      method: EMethod.post,
	      url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}`,
	      data: document,
	    })
	  }

export default (axios: AxiosInstance) => ({
  deleteOne: deleteOne(useAxios(axios)),
})
