import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'bankAccounts'

const getBankAccounts = (axios: IFactoryFunctionRequest<Array<any>, undefined>) => () => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/`,
  })
}

export default (axios: AxiosInstance) => ({
  getBankAccounts: getBankAccounts(useAxios(axios)),
})
