import type { RouteRecordRaw } from 'vue-router'

const MODULE_NAME = 'DOCUMENTATION'
const MODULE_ID = 99

const defaultMeta = {
}

const dashboardRoutes: Array<RouteRecordRaw> = [
  {
    path: '/documentation',
    meta: {
      ...defaultMeta,
    },
    children: [

      {
        path: '',
        name: 'DocumentationHome',
        component: () => import('@/views/application/documentation/HomeDocumentation.vue'),
        meta: {
          ...defaultMeta,
        },
      },
      {
        path: 'technical',
        children: [
          {
            path: '/api',
            name: 'DocumentationApis',
            component: () => import('@/views/application/documentation/technical/API.vue'),
            meta: {
              ...defaultMeta,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/documentation/technical/api',
    name: 'DocumentationApis',
    component: () => import('@/views/application/documentation/technical/API.vue'),
    meta: {
      ...defaultMeta,
    },
  },
]

export default dashboardRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }))
