import { useUserStore } from '@/config/store/userStore'
import { isModuleActive } from '@/services/application'

const moduleId = 7

const canUserAccessModule = (user) => {
  const isFirmAccess = isModuleActive(user.firm, moduleId)
  return isFirmAccess && user.profile.modules.evaluation.isAccess
}

export const guardEvaluationAccess = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = canUserAccessModule(user)
  return canAccess || { name: 'Home' }
}

export const guardCreation360 = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = user.profile.modules.evaluation?.create360
  return canAccess || { name: 'Home' }
}

export const guardCreationPeriod = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = user.profile.modules.evaluation?.createPeriod
  return canAccess || { name: 'Home' }
}

export const guardEditEvaluation = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = user.profile.modules.evaluation?.canEditEvaluation
  return canAccess || { name: 'Home' }
}

export const guardCreationProject = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = user.profile.modules.evaluation?.createProject
  return canAccess || { name: 'Home' }
}

export const guardCreationEvaluation = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = user.profile.modules.evaluation?.create360 || user.profile.modules.evaluation?.createPeriod || user.profile.modules.evaluation?.createProject
  return canAccess || { name: 'Home' }
}
