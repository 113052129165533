import { useUserStore } from '@/config/store/userStore'
import { isModuleActive } from '@/services/application'

const moduleId = 23

const canUserAccessModule = (user) => {
  const isFirmAccess = isModuleActive(user.firm, moduleId)
  return isFirmAccess && user.profile.modules.dashboard.isAccess
}
export const guardCashFlow = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.dashboard.cashFlowAccess ? user.profile.modules.dashboard.cashFlowAccess : { name: 'Home' }
}
export const guardProduction = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.dashboard.productionAccess ? user.profile.modules.dashboard.productionAccess : { name: 'Home' }
}
export const guardResource = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.dashboard.resourceAccess ? user.profile.modules.dashboard.resourceAccess : { name: 'Home' }
}
export const guardSales = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.dashboard.salesAccess ? user.profile.modules.dashboard.salesAccess : { name: 'Home' }
}

export const guardDashboardAccess = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = canUserAccessModule(user)
  return canAccess || { name: 'Home' }
}
