import type { AxiosInstance } from 'axios'
import type { ICompany, IFactoryFunctionRequest, paginate } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'companys'

/**
 * @deprecated
 * Use filter instead
 */
const getFilter = (axios: IFactoryFunctionRequest<Array<Pick<ICompany, '_id' | 'display'>>, undefined>) => (options = {}) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/filter`,
    query: options,
  })
}

const filter = (axios: IFactoryFunctionRequest<paginate<Array<Pick<ICompany, '_id' | 'display'>>>, undefined>) => (options = {}) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/filter`,
    query: options,
  })
}

const createOne = (axios: IFactoryFunctionRequest<Array<Pick<ICompany, '_id' | 'display'>>, undefined>) => (data) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}`,
    data,
  })
}

const getOneDetails = (axios: IFactoryFunctionRequest<ICompany, undefined>) => (clientId) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${clientId}/details`,
  })
}

const createNote = (axios: IFactoryFunctionRequest<{ headline: string; content: string; authorId: string }, undefined>) => (clientId, data) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${clientId}/createNote`,
    data,
  })
}

const editNote = (axios: IFactoryFunctionRequest<ICompany, undefined>) => (clientId, noteId, data) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${clientId}/editNote/${noteId}`,
    data,
  })
}

export default (axios: AxiosInstance) => ({
  getFilter: getFilter(useAxios(axios)),
  filter: filter(useAxios(axios)),
  createOne: createOne(useAxios(axios)),
  getOneDetails: getOneDetails(useAxios(axios)),
  createNote: createNote(useAxios(axios)),
  editNote: editNote(useAxios(axios)),
})
