import { useUserStore } from '@/config/store/userStore'
import { isModuleActive } from '@/services/application'

const moduleId = 4

const canUserAccessModule = (user) => {
  const isFirmAccess = isModuleActive(user.firm, moduleId)
  return isFirmAccess && user.profile.modules?.recruitment?.isAccess
}

export const guardRecruitmentAccess = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = canUserAccessModule(user)
  return canAccess || { name: 'Home' }
}

export const guardScreening = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = user.profile.modules.recruitment?.screeningAccess
  return canAccess || { name: 'Home' }
}

export const guardPipe = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = user.profile.modules.recruitment?.pipegAccess
  return canAccess || { name: 'Home' }
}

export const guardCandidateBase = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = user.profile.modules.recruitment?.candidateHistoryAccess
  return canAccess || { name: 'Home' }
}

export const guardJobBoard = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = user.profile.modules.recruitment?.jobBoardAccess
  return canAccess || { name: 'Home' }
}

export const guardJobBoardAdmin = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = user.profile.modules.recruitment?.jobBoardAdminAccess
  return canAccess || { name: 'Home' }
}

export const guardRecruitmentStatsAccess = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.recruitment.statsAccess ? user.profile.modules.recruitment.statsAccess : { name: 'Home' }
}

export const guardSettingsAccess = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.recruitment.settingsAcess ? user.profile.modules.recruitment.settingsAcess : { name: 'Home' }
}
