import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, paginate } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'boardingProcesses'

const filter = (axios: IFactoryFunctionRequest<paginate<{ display: string }>, any>) => (query: { page: number; limit: number; search?: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/filter`,
    query,
  })
}

/**
 * return a promise with the list of boarding processes with count of active processes for a country
 * need country and pagination values (page, limit)
 * @param axios 
 * @returns [Promise<paginate<AggregatedBoardingProcess>>]
 */
type IBoardingProcessListWithCount = { 
  onboarding: { processes: any[]; total: number; }; 
  offboarding: { processes: any[]; total: number; }; 
}
const getListWithCount = (axios: IFactoryFunctionRequest<IBoardingProcessListWithCount, any>) => ({ page, limit, country }: { page: number; limit: number; country: string }) => {
  const query = {
    page,
    limit,
  }
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/country/${country}`,
    query,
  })
}

export default (axios: AxiosInstance) => ({
  filter: filter(useAxios(axios)),
  getListWithCount: getListWithCount(useAxios(axios)),
})
