import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse, ISkill } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'skills'

/**
 * @deprecated use retreiveAll
 */
const getAll = (axios: IFactoryFunctionRequest) => ({ firmId }: { firmId: string }): IFactoryResponse<Array<ISkill>> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/${firmId}`,
  })
}

// used as v2 function
const retreiveAll = (axios: IFactoryFunctionRequest) => ({ firmId = undefined, categoryIds = undefined }: { firmId?: string; categoryIds?: string[] }): IFactoryResponse<Array<ISkill>> => {
  return axios({
    method: EMethod.get,
    url: !firmId ? `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2` : `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/public/${firmId}`,
    query: {
      categoryIds,

    },
  })
}

const getFilter = (axios: IFactoryFunctionRequest<Array<Pick<ISkill, '_id' | 'display'>>, undefined>) => (options = {}) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/filter`,
    query: options,
  })
}

export default (axios: AxiosInstance) => ({
  getAll: getAll(useAxios(axios)),
  getFilter: getFilter(useAxios(axios)),
  retreiveAll: retreiveAll(useAxios(axios)),
})
