import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse, ITraining } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'trainings'

const createOne = (axios: IFactoryFunctionRequest) => (trainingTemplate: ITraining): IFactoryResponse<ITraining> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/`,
    data: trainingTemplate,
  })
}

const getOne = (axios: IFactoryFunctionRequest) => (_id: string): IFactoryResponse<ITraining> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${_id}`,
  })
}

const updateOne = (axios: IFactoryFunctionRequest) => (_id: string, training: ITraining): IFactoryResponse<ITraining> => {
  // console.log('training', training);

  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${_id}`,
    data: training,
  })
}

const getAll = (axios: IFactoryFunctionRequest) => ({ firmId }: { firmId: string }): IFactoryResponse<Array<ITraining>> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/${firmId}`,
  })
}

const addDocument = (axios: IFactoryFunctionRequest) => (_id: string, file): IFactoryResponse<ITraining> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/document/${_id}`,
    data: file,
  })
}

export default (axios: AxiosInstance) => ({
  getAll: getAll(useAxios(axios)),
  getOne: getOne(useAxios(axios)),
  updateOne: updateOne(useAxios(axios)),
  addDocument: addDocument(useAxios(axios)),
  createOne: createOne(useAxios(axios)),
})
