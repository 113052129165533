import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IOffice } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'schools'

const filter = (axios: IFactoryFunctionRequest<Array<Pick<IOffice, '_id' | 'display'>>, undefined>) => ({
  page = 0,
  display = '',
  limit = 10,
  firmId,
}: { page?: number; display?: string; limit?: number; firmId?: string }) => {
  const url = firmId ? `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/paginated/jobBoard/${firmId}` : `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/paginated/`

  return axios({
    method: EMethod.get,
    url,
    query: { page, display, limit },
  })
}

export default (axios: AxiosInstance) => ({
  filter: filter(useAxios(axios)),
})
