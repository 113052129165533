import { useUserStore } from '@/config/store/userStore'
import { isModuleActive } from '@/services/application'

const moduleId = 20

const canUserAccessModule = (user) => {
  const isFirmAccess = isModuleActive(user.firm, moduleId)
  return isFirmAccess && user.profile.modules.timesheet.isAccess
}

export const guardTimesheetAccess = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = canUserAccessModule(user)
  return canAccess || { name: 'Home' }
}
