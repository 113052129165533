import type { RouteRecordRaw } from 'vue-router'
import { guardCashFlow, guardDashboardAccess, guardProduction, guardResource, guardSales } from '@/config/router/navigationsGuards/guardDashboard'

const MODULE_NAME = 'DASHBOARD'
const MODULE_ID = 23

const defaultMeta = {
  requiresAuth: true,
  adminAccess: true,
}

const dashboardRoutes: Array<RouteRecordRaw> = [
  {
    path: '/dashboard',
    meta: {
      ...defaultMeta,
    },
    beforeEnter: [guardDashboardAccess],
    children: [

      {
        path: 'production',
        name: 'DashboardProduction',
        component: () => import('@/views/dashboard/DashboardProduction.vue'),
        meta: {
          ...defaultMeta,
          titleTranslationKey: 'pageTitle.dashboard.production',
        },
        beforeEnter: [guardProduction],
      },
      {
        path: 'sales',
        name: 'DashboardSales',
        component: () => import('@/views/dashboard/DashboardSales.vue'),
        meta: {
          ...defaultMeta,
          titleTranslationKey: 'pageTitle.dashboard.sales',
        },
        beforeEnter: [guardSales],
      },
      {
        path: 'cashflow',
        name: 'DashboardCashflow',
        component: () => import('@/views/dashboard/CashFlow.vue'),
        meta: {
          ...defaultMeta,
          titleTranslationKey: 'pageTitle.dashboard.cashflow',
        },
        beforeEnter: [guardCashFlow],
      },
      {
        path: 'resource',
        name: 'DashboardResource',
        component: () => import('@/views/dashboard/Resource.vue'),
        meta: {
          ...defaultMeta,
          titleTranslationKey: 'pageTitle.dashboard.resource',
        },
        beforeEnter: [guardResource],
      },
    ],
  },
]

export default dashboardRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }))
