import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse, IInvoicingInformation } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'invoicingInformations'

const getFilter = (axios: IFactoryFunctionRequest<any, any>) => (query?: { optionalFields: string[] }): IFactoryResponse<Array<IInvoicingInformation>, undefined> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/filter`,
    query,
  })
}

const filter = (axios: IFactoryFunctionRequest<Array<Pick<IOffice, '_id' | 'display'>>, undefined>) => ({
  page = 0,
  display = '',
  limit = 30,
}: { page?: number; display?: string; limit?: number }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/filter`,
    query: { page, display, limit },
  })
}

const getInvoicingInformations = (axios: IFactoryFunctionRequest<any, any>) => () => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2`,
  })
}

export default (axios: AxiosInstance) => ({
  getFilter: getFilter(useAxios(axios)),
  filter: filter(useAxios(axios)),
  getInvoicingInformations: getInvoicingInformations(useAxios(axios)),
})
