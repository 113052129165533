import { reactive } from 'vue'
import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import type { IFactoryRequest, IFactoryResponse } from '@bc-library/interface'
import { stringifyQuery } from 'vue-router'

export default (axios: AxiosInstance) => <T, D>({ method, query = {}, url, data, immediate = true, headers = undefined }: IFactoryRequest<D>) => {
  const request = reactive({
    loading: immediate,
    data: undefined,
    loadingError: null,
  })
  const refresh: IFactoryResponse<T, D>['refresh'] = async ({ query, data, headers }) => {
    let urlWithQuery = url
    if (query)
      urlWithQuery = url.split('?')[0].length > 0 ? `${url.split('?')[0]}?${stringifyQuery(query)}` : `${url}?${stringifyQuery(query)}`
    if (request)
      request.loading = true
    const newPromise = axios({
      method,
      url: urlWithQuery,
      data,
      headers,
    }).then((response: AxiosResponse) => {
      request.data = response.data
      return response.data
    }).catch((err: AxiosError) => {
      request.loadingError = err
      return Promise.reject(err)
    }).finally(() => request.loading = false)
    request.promise = newPromise
    return newPromise
  }

  request.refresh = refresh

  if (immediate)
    refresh({ query, data })
  return request
}
