import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'
import { useUserStore } from '@/config/store/userStore'

let userStore
const getConnectedUser = () => {
  if (!userStore)
    userStore = useUserStore()
  return userStore
}

const resource = 'publicHolidays'

const getAll = (axios: IFactoryFunctionRequest) => (): IFactoryResponse<Array<any>> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}`,
  })
}

export default (axios: AxiosInstance) => ({
  getAll: getAll(useAxios(axios)),
})
