import type { AxiosInstance } from 'axios'
import type { ICompanyContact, IFactoryFunctionRequest } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'companyContacts'

const getFilter = (axios: IFactoryFunctionRequest<Array<Pick<ICompanyContact, '_id' | 'firstname' | 'lastname'>>, undefined>) => (options = {}) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/filter`,
    query: options,
  })
}

const getCompanyDetails = (axios: IFactoryFunctionRequest<Array<ICompanyContact>, undefined>) => (options = {}) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/companyDetails`,
    query: options,
  })
}

const createOne = (axios: IFactoryFunctionRequest<Array<Pick<ICompanyContact, '_id' | 'firstname'>>, undefined>) => (data) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}`,
    data,
  })
}

export default (axios: AxiosInstance) => ({
  getFilter: getFilter(useAxios(axios)),
  createOne: createOne(useAxios(axios)),
  getCompanyDetails: getCompanyDetails(useAxios(axios)),
})
