import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, ITeam } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'teams'

const getAll = (axios: IFactoryFunctionRequest<Array<ITeam>, undefined>) => ({ firmId, isAdmin }: { firmId: string; isAdmin: boolean }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/${firmId}/${isAdmin}`,
  })
}

const getAllFilter = (axios: IFactoryFunctionRequest<Array<Pick<ITeam, '_id' | 'display'>>, undefined>) => ({ isAdmin }: { isAdmin: boolean }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/filter/${isAdmin}`,
  })
}

const filter = (axios: IFactoryFunctionRequest<Array<Pick<ITeam, '_id' | 'display'>>, undefined>) => ({
  page = 0,
  display = '',
  limit = 30,
}: { page?: number; display?: string; limit?: number }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/filter`,
    query: { page, display, limit },
  })
}

export default (axios: AxiosInstance) => ({
  getAll: getAll(useAxios(axios)),
  filter: filter(useAxios(axios)),
  getAllFilter: getAllFilter(useAxios(axios)),
})
