import type { RouteRecordRaw } from 'vue-router'
import { guardTimesheetAccess } from '@/config/router/navigationsGuards/guardTimesheet'

const MODULE_NAME = 'TIMESHEET'
const MODULE_ID = 20

const timesheetRoutes: Array<RouteRecordRaw> = [
  {
    path: '/timesheet',
    meta: {
      requiresAuth: true,
    },
    beforeEnter: [guardTimesheetAccess],
    children: [
      {
        path: '',
        name: 'Timesheets',
        component: () => import('@/views/timesheet/List.vue'),
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.timesheets',
        },
        beforeEnter: [],
      },

    ],

  },
]

export default timesheetRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }))
