import type { RouteRecordRaw } from 'vue-router'

const MODULE_NAME = 'SALESFUNNEL'
const MODULE_ID = 8

const saleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/sales',
    meta: {
      requiresAuth: true,
    },
    beforeEnter: [],
    children: [
      {
        path: ':clientId',
        name: 'ClientDetails',
        component: () => import('@/views/sale/client/ClientDetails.vue'),
        meta: {
          requiresAuth: true,
        },
        beforeEnter: [],
      },

    ],

  },
]

export default saleRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }))
