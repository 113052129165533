import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'evaluations'

type TItems = 'title' | 'question' | 'smallText' | 'blank' | 'comment' | 'input' | 'rating' | 'abcd' | 'table' | 'boolean' | 'textCheckbox' | 'ranking'

type TShowEvaluation = {
  _id: string
  startDate: string
  endDate: string
  display: string
  formAfter: {
    _id: string
    column: [{
      _id: string
      type: TItems
      display: string | null
      displayArray: any[]
      value: any
      buttons: number
      calcId: string
      averageIds: string[]
      bgColor: string
      color: string
      required: boolean
      showNA: boolean
      hideable: boolean
      hiddenBy: string
      rows: number
    }]
  }[]
  form: {
    _id: string
    column: [{
      _id: string
      type: TItems
      display: string | null
      displayArray: any[]
      value: any
      buttons: number
      calcId: string
      averageIds: string[]
      bgColor: string
      color: string
      required: boolean
      showNA: boolean
      hideable: boolean
      hiddenBy: string
      rows: number
    }]
  }[]
}
const showFillEvaluation = (axios: IFactoryFunctionRequest<TShowEvaluation, undefined>) => ({ evaluationId }: { evaluationId: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/fill/${evaluationId}`,
  })
}

const showEditEvaluation = (axios: IFactoryFunctionRequest<TShowEvaluation, undefined>) => ({ evaluationId }: { evaluationId: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/edit/${evaluationId}`,
  })
}

const showFillEvaluationExternal = (axios: IFactoryFunctionRequest<TShowEvaluation, undefined>) => ({ evaluationId, email }: { evaluationId: string; email: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/fill/external/${evaluationId}`,
    query: { email },
  })
}
const showEvaluationList = (axios: IFactoryFunctionRequest<TShowEvaluation, undefined>) => (query: { limit: number; page: number; search?: string; type?: any; isClosed?: boolean }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/`,
    query,
  })
}
const retreiveCreateResources = (axios: IFactoryFunctionRequest<{ _id: string; firstname: string; lastname: string }[], undefined>) => (query: { resourceIds: string[] }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/retreive/create/resources`,
    query,
  })
}

const createEvaluations = (axios: IFactoryFunctionRequest<any, any>) => (evaluations: any[]) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/`,
    data: evaluations,
  })
}

const sendReminders = (axios: IFactoryFunctionRequest<any, any>) => (evaluations: any[]) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/sendReminder`,
    data: evaluations,
  })
}

const updateEvaluation = (axios: IFactoryFunctionRequest<any, undefined>) => ({ evaluationId, data, sendNotification = false, isExternal = false }: { isExternal?: boolean; evaluationId: string; data: any; sendNotification?: boolean }) => {
  const url = isExternal ? `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/update/external/${evaluationId}` : `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/update/${evaluationId}`
  return axios({
    method: EMethod.put,
    url,
    data,
    query: { sendNotification },
  })
}
const exportEvaluationsToExel = (axios: IFactoryFunctionRequest<IResource, any >) => (query) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/export`,
    query,
    immediate: false,
  })
}

const retreiveAllQuestionIdAvailable = (axios: IFactoryFunctionRequest<IResource, any >) => (query: { startDate: Date; endDate: Date }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/retreiveAllQuestionIdAvailable`,
    query,
  })
}
const retreiveStats = (axios: IFactoryFunctionRequest<IResource, any >) => (query: { startDate: Date; endDate: Date; ids: string[] }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/stats`,
    query,
  })
}

export default (axios: AxiosInstance) => ({
  showFillEvaluation: showFillEvaluation(useAxios(axios)),
  showEvaluationList: showEvaluationList(useAxios(axios)),
  updateEvaluation: updateEvaluation(useAxios(axios)),
  showFillEvaluationExternal: showFillEvaluationExternal(useAxios(axios)),
  retreiveCreateResources: retreiveCreateResources(useAxios(axios)),
  createEvaluations: createEvaluations(useAxios(axios)),
  showEditEvaluation: showEditEvaluation(useAxios(axios)),
  sendReminders: sendReminders(useAxios(axios)),
  exportEvaluationsToExel: exportEvaluationsToExel(useAxios(axios)),
  retreiveAllQuestionIdAvailable: retreiveAllQuestionIdAvailable(useAxios(axios)),
  retreiveStats: retreiveStats(useAxios(axios)),
})
