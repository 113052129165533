import type { RouteRecordRaw } from 'vue-router'

const MODULE_NAME = 'MISC'
const MODULE_ID = null

const hrRoutes: Array<RouteRecordRaw> = [
  {
    path: '/application/changelog',
    name: 'ApplicationChangelog',
    component: () => import('@/views/application/Changelog.vue'),
    meta: {
      requiresAuth: false,
      adminAccess: false,
    },
  },
]

export default hrRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }))
