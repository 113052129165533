import { version } from '../../package.json'

const { VITE_API_PROTOCOL, VITE_API_DOMAIN, VITE_API_PORT, VITE_WS_PROTOCOL = 'ws://', VITE_WS_DOMAIN = 'localhost:8080', VITE_SSO_ENTRY_POINT } = import.meta.env

export const API_PROTOCOL = VITE_API_PROTOCOL
export const API_DOMAIN = VITE_API_DOMAIN
export const API_PORT = VITE_API_PORT || ''
export const APP_DEV = import.meta.env.DEV

export const API_MAIN_VERSION = 'api'

export const BASE_URL = `${API_PROTOCOL}${API_DOMAIN}${API_PORT}`
export const APP_VERSION = `${version}`

export const WS_PROTOCOL = VITE_WS_PROTOCOL
export const WS_DOMAIN = VITE_WS_DOMAIN

export const SSO_ENTRY_POINT = VITE_SSO_ENTRY_POINT
