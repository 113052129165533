import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse, ISkill } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'skillCategorys'

const getAll = (axios: IFactoryFunctionRequest) => ({ firmId, categoryIds = [] }: { firmId: string; categoryIds?: Array<string> }): IFactoryResponse<Array<ISkill>, Array<ISkill>> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/${firmId}`,
    query: {
      categoryIds,
    },
  })
}

const getAllExternal = (axios: IFactoryFunctionRequest<Array<ISkill>, Array<ISkill>>) => ({ firmId, categoryIds = [] }: { firmId: string; categoryIds?: Array<string> }): IFactoryResponse<Array<ISkill>, Array<ISkill>> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/career/external`,
    query: {
      firmId,
      categoryIds,
    },
  })
}

export default (axios: AxiosInstance) => ({
  getAll: getAll(useAxios(axios)),
  getAllExternal: getAllExternal(useAxios(axios)),
})
