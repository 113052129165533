import type { RouteRecordRaw } from 'vue-router'
import { useJwt } from '@vueuse/integrations/useJwt'
import { useUserStore } from '@/config/store/userStore'

const MODULE_NAME = 'Auth'

const authRoutes: Array<RouteRecordRaw> = [
  {
    path: '/disconnection',
    name: 'Disconnection',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      requiresAuth: true,
      moduleId: 0,
    },
  },
  {
    path: '/connection',
    name: 'Login',
    component: () => import('@/views/login/Login.vue'),
    beforeEnter: [
      () => {
        const store = useUserStore()
        if (!store.token)
          return true
        const { payload } = useJwt(store.token)
        const timestamp = payload.value.exp
        if (new Date().getTime() < timestamp * 1000)
          return { name: 'Home' }
        else return true
      },
    ],
    meta: {
      requiresAuth: false,
      moduleId: 0,
      titleTranslationKey: 'pageTitle.login',
    },
  },
]

export default authRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME } }))
