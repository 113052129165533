import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'excel'

const getTabs = <T>(axios: IFactoryFunctionRequest<any, Array<T>>) => (excelData: Array<T>) => {
  return axios({
    method: EMethod.post,
    data: excelData,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/tabs`,
  })
}

export default (axios: AxiosInstance) => ({
  getTabs: getTabs(useAxios(axios)),
})
