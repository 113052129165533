import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'
import type { IJobBoardFirm } from '@/services/firm/IFirms'

const resource = 'firms'

const checkExists = (axios: IFactoryFunctionRequest) => ({ firmId }: { firmId: string }): IFactoryResponse<IJobBoardFirm> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/checkId/${firmId}`,
  })
}

const manageFirms = (axios: IFactoryFunctionRequest) => (query: { page: number; limit: number }): IFactoryResponse<any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/manage`,
    query,
  })
}
const manageFirm = (axios: IFactoryFunctionRequest) => (firmId): IFactoryResponse<any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/manage/${firmId}`,
  })
}
const manageFirmConsumtion = (axios: IFactoryFunctionRequest) => (firmId, query): IFactoryResponse<any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/manage/${firmId}/consumtion`,
    query,
  })
}
const firmEditInformations = (axios: IFactoryFunctionRequest) => (): IFactoryResponse<any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/edit`,
  })
}

const uploadFirmLogo = (axios: IFactoryFunctionRequest) => (logo): IFactoryResponse<any> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/uploadPicture`,
    data: logo,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

const partialUpdate = (axios: IFactoryFunctionRequest) => (data): IFactoryResponse<any> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/partialUpdate`,
    data,
  })
}

const firmSettings = (axios: IFactoryFunctionRequest) => (): IFactoryResponse<any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/firmSettings`,
  })
}

const updateFirmSettings = (axios: IFactoryFunctionRequest) => (data): IFactoryResponse<any> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/firmSettings`,
    data,
  })
}

export default (axios: AxiosInstance) => ({
  checkExists: checkExists(useAxios(axios)),
  manageFirms: manageFirms(useAxios(axios)),
  manageFirm: manageFirm(useAxios(axios)),
  manageFirmConsumtion: manageFirmConsumtion(useAxios(axios)),
  firmEditInformations: firmEditInformations(useAxios(axios)),
  uploadFirmLogo: uploadFirmLogo(useAxios(axios)),
  partialUpdate: partialUpdate(useAxios(axios)),
  firmSettings: firmSettings(useAxios(axios)),
  updateFirmSettings: updateFirmSettings(useAxios(axios)),
})
