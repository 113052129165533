import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse, IPublicHolidays } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'holidays'

/* const getCountry = (axios: IFactoryFunctionRequest) => ({ countryId }: { countryId: string }): IFactoryResponse<Array<IPublicHolidays>> => {
    return axios({
        method: EMethod.get,
        url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/country/${countryId}`
    })
} */
const getCountryBetweenDates = (axios: IFactoryFunctionRequest) => ({ startDate, endDate, countries }: { startDate: Date; endDate: Date; countries?: Array<string> }): IFactoryResponse<Array<IPublicHolidays>, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${startDate}/${endDate}`,
    query: { countries },
  })
}

export default (axios: AxiosInstance) => ({
  /* getCountry: getCountry(useAxios(axios)), */
  getCountryBetweenDates: getCountryBetweenDates(useAxios(axios)),
})
