import type { AxiosInstance } from 'axios'
import type { ICountry, IFactoryFunctionRequest, IFactoryResponse, IPaginated, IResource } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'
import { useUserStore } from '@/config/store/userStore'
import type { ICandidateApplication } from '@/services/resource/interfaces'

let userStore
const getConnectedUser = () => {
  if (!userStore)
    userStore = useUserStore()
  return userStore
}

const resource = 'resources'

// const getConnectedResource = (axios: AxiosInstance) => (): AxiosPromise => {
//     return axios.get(`${BASE_URL}/${API_MAIN_VERSION}/${resource}/me`, {headers: {authorization: `Bearer ${userStore.getToken}`}});
// }

const hrBaseList = (axios: IFactoryFunctionRequest<IPaginated<IResource>, any>) => (query: { page: number; limit: number; display?: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/employeeList/paginated`,
    query,
  })
}

const getHrResourceProfile = (axios: IFactoryFunctionRequest<IResource, any>) => (resourceId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/hrProfile/${resourceId}`,
  })
}

const getAllLight = (axios: IFactoryFunctionRequest<Array<IResource>, any>) => ({ firmId }: { firmId: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/light/${firmId}`,
  })
}

const showPaginatedLight = (axios: IFactoryFunctionRequest<Array<IResource>, any>) => ({
  page = 1,
  search = '',
  jobs = [],
  limit = 30,
}: { page?: number; search?: string; jobs?: string[]; limit?: number }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/paginated/light`,
    query: { page, search, jobs, limit },
  })
}

const showPaginatedLeaveValidator = (axios: IFactoryFunctionRequest<Array<IResource>, any>) => ({
  page = 1,
  search = '',
  jobs = [],
  limit = 30,
  leaveTypeId,
  startDate,
  endDate,
}: { page?: number; search?: string; jobs?: string[]; limit?: number; leaveTypeId: string; startDate: Date; endDate: Date }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/leave/validators/${leaveTypeId}/${startDate}/${endDate}`,
    query: { page, search, jobs, limit },
  })
}

const updateResource = (axios: IFactoryFunctionRequest<IResource, IResource>) => (resourceId: string, resourceToSend: any) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${resourceId}`,
    data: resourceToSend,
  })
}

const createCandidateApplication = (axios: IFactoryFunctionRequest<undefined, ICandidateApplication>) => (candidateApplication: ICandidateApplication) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/candidateApplication`,
    data: candidateApplication,
  })
}

const getConnectedResource = (axios: IFactoryFunctionRequest<IResource, any>) => (): IFactoryResponse<IResource, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/connectedUser`,
  })
}

const getOne = (axios: IFactoryFunctionRequest<IResource, any>) => (resourceId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/profile/${resourceId}`,
  })
}
const getOneToStaff = (axios: IFactoryFunctionRequest<IResource, any>) => (resourceId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/project/getOne/toStaff/${resourceId}`,
  })
}
const getInternalInterval = (axios: IFactoryFunctionRequest<Array<IResource>, any>) => ({
  search,
  startDate,
  endDate,
  jobs = [],
}: { search?: string; startDate: Date; endDate: Date; jobs?: string[] }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/planning/interval/${startDate}/${endDate}`,
    query: { jobs, search },
  })
}

const getSuperAdminResourceList = (axios: IFactoryFunctionRequest<IPaginated<IResource>, any>) => (query: { page: number; limit: number; display?: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/superAdminList`,
    query,
  })
}

const filter = (axios: IFactoryFunctionRequest<IPaginated<IResource>, any>) => (query: { page: number; limit: number; display?: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/filter`,
    query,
  })
}

const getResourceCountByProfileId = (axios: IFactoryFunctionRequest<{ count: number }, any>) => (profileId) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/profile/${profileId}/count`,
  })
}

const getSmallestResourceInfo = (axios: IFactoryFunctionRequest<{ firstname: string; lastname: string; picture: string }[], any>) => (resourceIds: string[]) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/smallestResources`,
    data: { resourceIds },
  })
}

const getResourceBulkLeaveCreate = (axios: IFactoryFunctionRequest<IPaginated<{ _id: string; firstname: string; lastname: string; picture: string }>, any>) => (query) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/leave/bulkCreate`,
    query,
  })
}
const updateResourcePicture = (axios: IFactoryFunctionRequest<{ picture: string }, any>) => (resourceId: string, { base64 }: { base64: string }) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/picture/${resourceId}`,
    data: { data: base64 },
  })
}

const retreiveInterviewAttendee = (axios: IFactoryFunctionRequest<{ _id: string; firstname: string; lastname: string; userId: string; user: { _id: string; email: string } }, any>) => (resourceId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/recruitment/retreiveInterviewAttendee/${resourceId}`,
  })
}

const exportResourcesToExel = (axios: IFactoryFunctionRequest<IResource, any >) => (query) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/export`,
    query,
    immediate: false,
  })
}
const exportResourcesRetentionToExcel = (axios: IFactoryFunctionRequest<IResource, any >) => (query) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/export/retention`,
    query,
    immediate: false,
  })
}

const candidateToResource = (axios: IFactoryFunctionRequest<IResource, any >) => (body) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/candidateToResource`,
    data: body,
  })
}
const partialUpdate = (axios: IFactoryFunctionRequest<IResource, any >) => (body) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${body._id}`,
    data: body,
  })
}

const searchIfResourceExist = (axios: IFactoryFunctionRequest<IResource, any >) => ({ firstname, lastname, email }: { firstname: string; lastname: string; email: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/searchIfResourceExist`,
    query: {
      firstname,
      lastname,
      email,
    },
  })
}


const getManagedResources = (axios: IFactoryFunctionRequest<IResource, any >) => (resourceId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/managedResources/${resourceId}`
  })
}


// router.post('/:resourceId/skill/:skillId/tag', auth.isAuth(), skillController.tagSkill);
const tagSkill = (axios: IFactoryFunctionRequest<IResource, any >) => (resourceId: string, skillId: string) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/skill/${skillId}/tag`
  })
}
// router.delete('/:resourceId/skill/:skillId/tag', auth.isAuth(), skillController.untagSkill);
const untagSkill = (axios: IFactoryFunctionRequest<IResource, any >) => (resourceId: string, skillId: string) => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/skill/${skillId}/tag`
  })
}

// router.post('/:resourceId/skill/:skillId', auth.isAuth(), skillController.addSkill);
const addSkill = (axios: IFactoryFunctionRequest<IResource, any >) => (resourceId: string, skillId: string) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/skill/${skillId}`
  })
}

// router.put('/:resourceId/skill/:skillId', auth.isAuth(), skillController.updateSkill);
const updateSkill = (axios: IFactoryFunctionRequest<IResource, any >) => (resourceId: string, skillId: string, skillUpdate: any) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/skill/${skillId}`,
    data: skillUpdate
  })
}
// router.delete('/:resourceId/skill/:skillId', auth.isAuth(), skillController.deleteSkill);
const deleteSkill = (axios: IFactoryFunctionRequest<IResource, any >) => (resourceId: string, skillId: string) => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/skill/${skillId}`
  })
}

// router.patch('/:resourceId/nationalities', auth.isAuth(), controller.updateNationalities);
const updateNationalities = (axios: IFactoryFunctionRequest<IResource, any >) => (resourceId: string, {nationalities, nationality}: { nationalities: any[], nationality: string }) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/nationalities`,
    data: { nationalities, nationality }
  })
}

const getResourceOfficesCountries = (axios: IFactoryFunctionRequest<Array<ICountry>, any >) => (resourceId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/OfficesCountries/${resourceId}`,
  })
}




export default (axios: AxiosInstance) => ({
  // getConnectedResource: getConnectedResource(axios),
  getAllLight: getAllLight(useAxios(axios)),
  showPaginatedLight: showPaginatedLight(useAxios(axios)),
  getHrResourceProfile: getHrResourceProfile(useAxios(axios)),
  hrBaseList: hrBaseList(useAxios(axios)),
  updateResource: updateResource(useAxios(axios)),
  partialUpdate: partialUpdate(useAxios(axios)),
  createCandidateApplication: createCandidateApplication(useAxios(axios)),
  getConnectedResource: getConnectedResource(useAxios(axios)),
  getOne: getOne(useAxios(axios)),
  getOneToStaff: getOneToStaff(useAxios(axios)),
  getInternalInterval: getInternalInterval(useAxios(axios)),
  getResourceCountByProfileId: getResourceCountByProfileId(useAxios(axios)),
  showPaginatedLeaveValidator: showPaginatedLeaveValidator(useAxios(axios)),
  getSuperAdminResourceList: getSuperAdminResourceList(useAxios(axios)),
  getSmallestResourceInfo: getSmallestResourceInfo(useAxios(axios)),
  filter: filter(useAxios(axios)),
  getResourceBulkLeaveCreate: getResourceBulkLeaveCreate(useAxios(axios)),
  updateResourcePicture: updateResourcePicture(useAxios(axios)),
  retreiveInterviewAttendee: retreiveInterviewAttendee(useAxios(axios)),
  exportResourcesToExel: exportResourcesToExel(useAxios(axios)),
  candidateToResource: candidateToResource(useAxios(axios)),
  searchIfResourceExist: searchIfResourceExist(useAxios(axios)),
  exportResourcesRetentionToExcel: exportResourcesRetentionToExcel(useAxios(axios)),
  getManagedResources: getManagedResources(useAxios(axios)),
  addSkill: addSkill(useAxios(axios)),
  tagSkill: tagSkill(useAxios(axios)),
  untagSkill: untagSkill(useAxios(axios)),
  updateSkill: updateSkill(useAxios(axios)),
  deleteSkill: deleteSkill(useAxios(axios)),
  updateNationalities: updateNationalities(useAxios(axios)),
  getResourceOfficesCountries: getResourceOfficesCountries(useAxios(axios)),
})
