import { useUserStore } from '@/config/store/userStore'
import { isModuleActive } from '@/services/application'

const moduleId = 6

export const canUserAccessModule = (user) => {
  const isFirmAccess = isModuleActive(user.firm, moduleId)
  return isFirmAccess && user.profile.modules.leave.isAccess
}

export const guardLeaveAccess = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = canUserAccessModule(user)
  return canAccess || { name: 'Home' }
}

export const guardRequest = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.leave?.requestAccess ? user.profile.modules.leave.requestAccess : { name: 'Home' }
}

export const guardNewRequest = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.leave?.newRequestAccess ? user.profile.modules.leave.newRequestAccess : { name: 'Home' }
}
export const guardNewBulkRequest = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.leave?.bulkCreateAccess ? user.profile.modules.leave.bulkCreateAccess : { name: 'Home' }
}

export const guardCalendar = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.leave?.calendarAccess ? user.profile.modules.leave.calendarAccess : { name: 'Home' }
}
