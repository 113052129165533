import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { ICountry } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'countrys'

// const getAll = (axios: IFactoryFunctionRequest<Array<ICountry>, undefined>) => () => {
//   return axios({
//     method: EMethod.get,
//     url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/${firmId}`,
//   })
// }


const getAllPaginated = (axios: IFactoryFunctionRequest<Array<ICountry>, any>) => ( {
  page = 0,
  limit = 24,
  searchString
}:
{
  page?: number
  searchString?: string
  limit?: number
}) => {
  const query = { page, searchString, limit }
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/paginated`,
    query,
  })
}


export default (axios: AxiosInstance) => ({
  // getAll : getAll(useAxios(axios)),
  getAllPaginated: getAllPaginated(useAxios(axios)),
})
