<script lang="ts" setup>
import MaterialSymbolsArrowForwardIosRounded from '~icons/material-symbols/arrow-forward-ios-rounded'
import ResourceImage from '@/components/resource/resourceImage.vue'
import BLabel from '@/components/common/bLabel.vue'

const props = defineProps<{
  resource: {
    _id: string
    firstname: string
    lastname: string
    picture: string
    currentJob: {
      position: {
        acronym: string
        display: string
      }
    }
  }
}>()
const emit = defineEmits<{
  openSearch: []
}>()
</script>

<template>
  <li
    class="group transition flex cursor-default items-center rounded-md p-2 group hover:bg-primary-600 hover:cursor-pointer"
    role="option" tabindex="-1"
  >
    <RouterLink
      :to="{ name: 'EmployeeProfile', params: { resourceId: resource._id } }" class="w-full flex flex-wrap gap-4"
      @click="emit('openSearch')"
    >
      <div class="flex gap-1">
        <BLabel size="dense" :title="resource.currentJob.position.display">
          {{ resource.currentJob.position.acronym }}
        </BLabel>
        <ResourceImage :resource="resource" size="h-6 w-6" />
      </div>
      <p class="ml-3 flex-auto truncate group-hover:text-white">
        {{ resource.firstname }} {{ resource.lastname }}
      </p>
      <MaterialSymbolsArrowForwardIosRounded class="ml-3 h-5 w-5 flex-none group-hover:text-white" />
    </RouterLink>
  </li>
</template>
