import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'timesheetActivitys'

/**
 *
 * @deprecated
 * use filter instead
 *
 */
const getAll = (axios: IFactoryFunctionRequest<any, any>) => (): IFactoryResponse<Array<any>, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2`,
  })
}

const filter = (axios: IFactoryFunctionRequest<any, any>) => (): IFactoryResponse<Array<any>, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/filter`,
  })
}

export default (axios: AxiosInstance) => ({
  getAll: getAll(useAxios(axios)),
  filter: filter(useAxios(axios)),
})
