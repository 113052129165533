import type { AxiosInstance } from 'axios'
import type { ICompany, IFactoryFunctionRequest, paginate } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'messages'

const interviewsMessages = (axios: IFactoryFunctionRequest<paginate<Array<Pick<ICompany, '_id' | 'display'>>>, undefined>) => (processId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/recruitmentProcess/${processId}/interviews`,
  })
}

export default (axios: AxiosInstance) => ({
  interviewsMessages: interviewsMessages(useAxios(axios)),
})
