import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse, IPaginated } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'rooms'

const getAll = (axios: IFactoryFunctionRequest) => (firmId: string): IFactoryResponse<Array<IRoom>> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/${firmId}`,
  })
}

type TPaginatedResponse = Array< '_id' | 'display'>
const filter = (axios: IFactoryFunctionRequest<IPaginated<TPaginatedResponse>, any>) => ({
  page = 0,
  search = '',
  limit = 30,
}: { page?: number; search?: string; limit?: number }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/filter`,
    query: { page, search, limit },
  })
}

export default (axios: AxiosInstance) => ({
  getAll: getAll(useAxios(axios)),
  filter: filter(useAxios(axios)),
})
