import axios, { AxiosInstance } from 'axios'
import { EMethod, IFactoryFunctionRequest, IFactoryResponse, IOffice, IPaginated} from "@bc-library/interface";
import { BASE_URL, API_MAIN_VERSION } from '@/config/constantes';
import useAxios from "@/services/api/useAxios";

const resource = 'invoicingProjects'

type optionsInvoice = {
  _firmId: string;
  _id: string;
};

const postNewLine = (axios: IFactoryFunctionRequest<optionsInvoice, undefined>) => ({projectId, data}: {projectId: string, data: any}) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/project/${projectId}`,
    data,
  })
};

const getAllInvoices = (axios: IFactoryFunctionRequest<IPaginated<TShowInvoice>, undefined>) => (query: { limit: number, page: number, status: string, search: string, }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/all`,
    query,
  })
};

const updateInvoice = (axios: IFactoryFunctionRequest<any, undefined>) => ({ _id, ...data }: { _id: string }) => {
  return axios({
      method: EMethod.put,
      url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${_id}`,
      data,
  })
}

export default (axios: AxiosInstance) => ({
  postNewLine: postNewLine(useAxios(axios)),
  getAllInvoices: getAllInvoices(useAxios(axios)),
  updateInvoice: updateInvoice(useAxios(axios)),
})
