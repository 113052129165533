<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CountryFlag from './CountryFlag.vue'

withDefaults(defineProps<{
  size?: string
}>(), {
  size: 'w-6',
})

const { locale } = useI18n()

const changeLocal = () => {
  locale.value = locale.value === 'fr' ? 'en' : 'fr'
}
const currentCountryImage = computed(() => {
  return `/flag_${locale.value === 'fr' ? 'FR' : 'EN'}.webp`
})
</script>

<template>
  <span title="Switch application language" class="tracking-wide cursor-pointer text-xl ml-auto" @click="changeLocal">
    <img :class="[size]" height="15" :src="currentCountryImage" alt="Office country">
  </span>
</template>
