/*
 * All i18n resources specified in the plugin `include` option can be loaded
 * at once using the import syntax
 */
import messages from '@intlify/unplugin-vue-i18n/messages'

import { createI18n } from 'vue-i18n'

export default createI18n({
  locale: window.navigator.language.includes('fr') ? 'fr' : 'en',
  fallbackLocale: 'fr',
  legacy: false,
  globalInjection: true,
  messages,
})
