import type { RouteRecordRaw } from 'vue-router'
import { canUserAccessModule, guardProfiles, guardUsers } from '@/config/router/navigationsGuards/guardSuperAdmin'

const MODULE_NAME = 'SUPER_ADMIN'
const MODULE_ID = 'SUPER_ADMIN'

const defaultMeta = {
  requiresAuth: true,
  adminAccess: true,
}
const prefix = '/super-admin'

const superAdminRoutes: Array<RouteRecordRaw> = [
  {
    path: '/user-management',
    meta: {
      ...defaultMeta,
    },
    beforeEnter: [() => canUserAccessModule],
    children: [
      {
        path: 'users',
        name: 'SuperAdminUsers',
        component: () => import('@/views/superAdmin/users/List.vue'),
        meta: {
          ...defaultMeta,
        },
        beforeEnter: [guardUsers],
      },
      {
        path: 'profiles',
        name: 'SuperAdminProfiles',
        component: () => import('@/views/superAdmin/users/profile/ProfileList.vue'),
        meta: {
          ...defaultMeta,
        },
        beforeEnter: [guardProfiles],
      },
      {
        path: 'profiles/:profileId',
        name: 'SuperAdminProfile',
        component: () => import('@/views/superAdmin/users/profile/ProfileItem.vue'),
        meta: {
          ...defaultMeta,
        },
        beforeEnter: [guardProfiles],
      },
    ],
  },
  {
    path: '/firm-management',
    meta: {
      ...defaultMeta,
    },
    beforeEnter: [() => canUserAccessModule],
    children: [
      {
        path: 'personalisation',
        name: 'SuperAdminPersonalisation',
        component: () => import('@/views/superAdmin/firm/Personnalisation.vue'),
        meta: {
          ...defaultMeta,
        },
        beforeEnter: [guardUsers],
      },
    ],
  },
]

export default superAdminRoutes.map(route => ({ ...route, path: prefix + route.path, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }))
