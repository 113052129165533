import { RouteRecordRaw } from "vue-router";
import {guardEnterProject} from "@/config/router/navigationsGuards/guardProject";

const MODULE_NAME = 'INVOICES';
const MODULE_ID = 19;

const evaluationRoutes: Array<RouteRecordRaw> = [
    {
        path: '/invoice',
        meta: {
            requiresAuth: true,
        },
        beforeEnter: [guardEnterProject],
        children: [
            {
                path: '',
                name: 'Invoices',
                component: () => import('@/views/invoice/List.vue'),
                meta: {
                    requiresAuth: true
                },
                beforeEnter: [],
            },
            {
                path: ':projectId',
                name: 'Invoice',
                component: () => import('@/views/invoice/ProjectInvoiceDetails.vue'),
                meta: {
                    requiresAuth: true
                },
                beforeEnter: [],
            },
        ]

    },
    {
        path: '/invoicelist',
        meta: {
            requiresAuth: true,
        },
        beforeEnter: [guardEnterProject],
        children: [
            {
                path: '/invoicelist',
                name: 'InvoiceList',
                component: () => import('@/views/invoice/invoiceList.vue'),
                meta: {
                    requiresAuth: true
                },
                beforeEnter: [],
            },
        ]
    },
]

export default evaluationRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }));
