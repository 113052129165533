import type { RouteRecordRaw } from 'vue-router'
import { guardCalendar, guardLeaveAccess, guardNewBulkRequest, guardNewRequest, guardRequest } from '@/config/router/navigationsGuards/guardLeave'

const MODULE_NAME = 'LEAVE'
const MODULE_ID = 6

const timesheetRoutes: Array<RouteRecordRaw> = [
  {
    path: '/leave',
    meta: {
      requiresAuth: true,
    },
    beforeEnter: [guardLeaveAccess],
    children: [
      {
        path: '',
        name: 'LeaveRequests',
        component: () => import('@/views/leave/LeaveRequests.vue'),
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.leaveRequests',
        },
        beforeEnter: [guardRequest],
      },
      {
        path: 'new',
        name: 'NewLeaveRequest',
        component: () => import('@/views/leave/LeaveNewRequest.vue'),
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.newLeaveRequest',
        },
        beforeEnter: [guardNewRequest],
      },
      {
        path: 'new/bulk',
        name: 'NewBulkLeaveRequest',
        component: () => import('@/views/leave/Bulk.vue'),
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.newBulkLeaveRequest',
        },
        beforeEnter: [guardNewBulkRequest],
      },
      {
        path: 'request/:resourceId/:leaveId',
        name: 'LeaveRequest',
        component: () => import('@/views/leave/LeaveRequest.vue'),
        meta: {
          requiresAuth: true,
        },
        beforeEnter: [guardRequest],
      },
      {
        path: 'account',
        name: 'LeaveAccount',
        component: () => import('@/views/leave/LeaveAccount.vue'),
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.leaveAccount',
        },
        beforeEnter: [],
      },
      {
        path: 'calendar',
        name: 'LeaveCalendar',
        component: () => import('@/views/leave/LeaveCalendar.vue'),
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.leaveCalendar',
        },
        beforeEnter: [guardCalendar],
      },

    ],

  },
]

export default timesheetRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }))
