import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'
import type { IJobBoardFirm } from '@/services/firm/IFirms'

const resource = 'managements'

const getServers = (axios: IFactoryFunctionRequest<any,any>) => (): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/manage/servers`,
  })
}

export default (axios: AxiosInstance) => ({
  getServers: getServers(useAxios(axios)),
})
