import { useUserStore } from '@/config/store/userStore'
import { isModuleActive } from '@/services/application'

const moduleId = 5

const canUserAccessModule = (user) => {
  const isFirmAccess = isModuleActive(user.firm, moduleId)
  return isFirmAccess && user.profile.modules.project.isAccess
}
export const guardProjectCreation = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.project.createAccess ? user.profile.modules.project.createAccess : { name: 'Home' }
}
export const guardPlanningResource = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.project.planningResourceAccess ? user.profile.modules.project.planningResourceAccess : { name: 'Home' }
}
export const guardStats = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.project.statsAccess ? user.profile.modules.project.statsAccess : { name: 'Home' }
}
export const guardSettings = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.project.settingsAccess ? user.profile.modules.project.settingsAccess : { name: 'Home' }
}

export const guardEnterProject = () => {
  const userStore = useUserStore()
  const user = userStore.user

  return user.profile.modules.project.overviewOnlyPublic === false
}

export const guardProjectAccess = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = canUserAccessModule(user)
  return canAccess || { name: 'Home' }
}

export const accessStaffing = (project) => {
  const userStore = useUserStore()
  const user = userStore.user
  const projectRights = user.profile.modules.project

  const isDirectorId = user._id === project.directorId
  const isManagerId = user._id === project.managerId
  const isContributor = project.contributors.some(contributor => contributor.contributorId === user._id)

  if (projectRights.onDirectorProject.isEnabled && isDirectorId && projectRights.onDirectorProject.staffing)
    return true
  if (projectRights.onManagerProject.isEnabled && isManagerId && projectRights.onManagerProject.staffing)
    return true
  if (projectRights.onContributorProject.isEnabled && isContributor && projectRights.onContributorProject.staffing)
    return true
  if (projectRights.overviewOnlyPublic === false && projectRights.onGlobalAccess.staffing)
    return true
  return { name: 'Home' }
}

export const accessBudget = (project) => {
  const userStore = useUserStore()
  const user = userStore.user
  const projectRights = user.profile.modules.project

  const isDirectorId = user._id === project.directorId
  const isManagerId = user._id === project.managerId
  const isContributor = project.contributors.some(contributor => contributor.contributorId === user._id)

  if (projectRights.onDirectorProject.isEnabled && isDirectorId && projectRights.onDirectorProject.budget)
    return true
  if (projectRights.onManagerProject.isEnabled && isManagerId && projectRights.onManagerProject.budget)
    return true
  if (projectRights.onContributorProject.isEnabled && isContributor && projectRights.onContributorProject.budget)
    return true
  if (projectRights.overviewOnlyPublic === false && projectRights.onGlobalAccess.budget)
    return true
  return { name: 'Home' }
}

export const accessMonitoring = (project) => {
  const userStore = useUserStore()
  const user = userStore.user
  const projectRights = user.profile.modules.project

  const isDirectorId = user._id === project.directorId
  const isManagerId = user._id === project.managerId
  const isContributor = project.contributors.some(contributor => contributor.contributorId === user._id)

  if (projectRights.onDirectorProject.isEnabled && isDirectorId && projectRights.onDirectorProject.monitoring)
    return true
  if (projectRights.onManagerProject.isEnabled && isManagerId && projectRights.onManagerProject.monitoring)
    return true
  if (projectRights.onContributorProject.isEnabled && isContributor && projectRights.onContributorProject.monitoring)
    return true
  if (projectRights.overviewOnlyPublic === false && projectRights.onGlobalAccess.monitoring)
    return true
  return { name: 'Home' }
}

export const accessTimesheet = (project) => {
  const userStore = useUserStore()
  const user = userStore.user
  const projectRights = user.profile.modules.project

  const isDirectorId = user._id === project.directorId
  const isManagerId = user._id === project.managerId
  const isContributor = project.contributors.some(contributor => contributor.contributorId === user._id)

  if (projectRights.onDirectorProject.isEnabled && isDirectorId && projectRights.onDirectorProject.timesheet)
    return true
  if (projectRights.onManagerProject.isEnabled && isManagerId && projectRights.onManagerProject.timesheet)
    return true
  if (projectRights.onContributorProject.isEnabled && isContributor && projectRights.onContributorProject.timesheet)
    return true
  if (projectRights.overviewOnlyPublic === false && projectRights.onGlobalAccess.timesheet)
    return true
  return { name: 'Home' }
}

export const accessEdit = (project) => {
  const userStore = useUserStore()
  const user = userStore.user
  const projectRights = user.profile.modules.project

  const isDirectorId = user._id === project.directorId
  const isManagerId = user._id === project.managerId
  const isContributor = project.contributors.some(contributor => contributor.contributorId === user._id)

  if (projectRights.onDirectorProject.isEnabled && isDirectorId && projectRights.onDirectorProject.editProject)
    return true
  if (projectRights.onManagerProject.isEnabled && isManagerId && projectRights.onManagerProject.editProject)
    return true
  if (projectRights.onContributorProject.isEnabled && isContributor && projectRights.onContributorProject.editProject)
    return true
  if (projectRights.overviewOnlyPublic === false && projectRights.onGlobalAccess.editProject)
    return true
  return { name: 'Home' }
}
