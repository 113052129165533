import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'searches'

const globalSearch = (axios: IFactoryFunctionRequest) => (): IFactoryResponse<Array<any>> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}`,
    immediate: false,
  })
}

export default (axios: AxiosInstance) => ({
  globalSearch: globalSearch(useAxios(axios)),
})
