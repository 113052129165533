<script setup lang="ts">
import { ref } from 'vue'
import { animate } from 'motion'
import IconClose from '~icons/ic/round-close'
import { EModalPosition } from '@/services/application/interfaces'
import { ModalPosition } from '@/services/application/constantes'

const props = withDefaults(defineProps<{ disableBackDrop?: boolean; modelValue: boolean; haveHeader?: boolean; position?: keyof typeof EModalPosition }>(), {
  modelValue: false,
  position: EModalPosition.base,
  haveHeader: true,
  disableBackDrop: false,
})

const emit = defineEmits(['update:modelValue'])

type closeSource = 'unknow' | 'backdrop' | 'closeIcon'

const toggleModal = (source: closeSource = 'unknow') => {
  if (source === 'backdrop' && props.disableBackDrop)
    return
  emit('update:modelValue', !props.modelValue)
}
const isModalClosed = ref(true)

const modalAnimation = {
  base: {
    enter: (element, done) => {
      isModalClosed.value = false
      animate(element, {
        opacity: [0.5, 1],
        transform: ['translate(0, 10%)', 'translate(0, 0)'],
      }).finished.then(done)
    },
    leave: (element, done) => {
      animate(element, {
        opacity: [1, 0],
        scale: [1, 0.8],
      }).finished.then(() => {
        isModalClosed.value = true
        done()
      })
    },
  },
  bottom: {
    enter: (element, done) => {
      isModalClosed.value = false
      animate(element, {
        transform: ['translate(-50%, 100%)', 'translate(-50%, 0)'],
      }).finished.then(done)
    },
    leave: (element, done) => {
      animate(element, {
        transform: ['translate(-50%, 0)', 'translate(-50%, 100%)'],

      }).finished.then(() => {
        element.style.transform = undefined
        isModalClosed.value = true
        done()
      })
    },
  },
  top: {
    enter: (element, done) => {
      isModalClosed.value = false
      animate(element, {
        transform: ['translate(-50%, -100%)', 'translate(-50%, 0)'],
      }).finished.then(done)
    },
    leave: (element, done) => {
      animate(element, {
        transform: ['translate(-50%, 0)', 'translate(-50%, -100%)'],

      }).finished.then(() => {
        element.style.transform = undefined
        isModalClosed.value = true
        done()
      })
    },
  },
  right: {
    enter: (element, done) => {
      isModalClosed.value = false
      animate(element, {
        transform: ['translate(50%, 0%)', 'translate(0%, 0%)'],
      }).finished.then(done)
    },
    leave: (element, done) => {
      animate(element, {
        transform: ['translate(0%, 0)', 'translate(100%, 0%)'],
      }).finished.then(() => {
        isModalClosed.value = true
        done()
      })
    },
  },
  left: {
    enter: (element, done) => {
      isModalClosed.value = false
      animate(element, {
        transform: ['translate(-100%, 0%)', 'translate(0%, 0%)'],
      }).finished.then(done)
    },
    leave: (element, done) => {
      animate(element, {
        transform: ['translate(0%, %0)', 'translate(-100%, 0%)'],
      }).finished.then(() => {
        isModalClosed.value = true
        done()
      })
    },
  },
}
</script>

<script lang="ts">
// use normal <script> to declare options
export default {
  inheritAttrs: false,
}
</script>

<template>
  <Teleport to="body">
    <div :class="{ modal: !isModalClosed }" class="relative">
      <transition name="fade">
        <div v-show="!isModalClosed" class="backdrop" @click="toggleModal('backdrop')" />
      </transition>
      <transition @enter="modalAnimation[position].enter" @leave="modalAnimation[position].leave">
        <div
          v-show="modelValue" class="modal-container bg-base rounded shadow-lg flex flex-col" v-bind="$attrs"
          :class="[ModalPosition[position], $attrs.class]"
        >
          <header v-if="haveHeader" class="flex items-center justify-between p-3 shadow">
            <slot name="header" />
            <IconClose class="ml-auto cursor-pointer hover:text-primary-500" @click="toggleModal('closeIcon')" />
          </header>
          <main class="p-2 lg:p-5 flex flex-col grow">
            <slot />
          </main>
          <footer class="p-3">
            <slot name="footer" />
          </footer>
        </div>
      </transition>
    </div>
  </Teleport>
</template>

<style scoped lang="scss">
.b-modal .modal-container{
  transform-origin: center;
}
.bottom-modal .modal-container{
  bottom: 0;
  transform-origin: top;
}
.bottom-modal .modal-container{
  top: 0;
  transform-origin: top;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100dvw;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  will-change: transform;

  .backdrop {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.45);
  }
}

.fade-y-bottom-enter-from,
.fade-y-bottom-leave-to {
  opacity: 0;
  bottom: 0 !important;
  transform: translate3d(-50%, 100%, 0) !important;
}

.fade-x-right-enter-active,
.fade-y-bottom-leave-active {
  transition: all 0.2s ease-in;
}

.fade-x-right-enter-from,
.fade-x-right-leave-to {
  opacity: 0;
  transform: translate3d(50%, 0%, 0) !important;
}

.right-modal {
  max-width: calc(98dvw - 1dvw);
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 98dvh;
  right: 1dvw;
  border-radius: 15px 15px 15px 15px;
}

.right-modal main {
  max-height: 100%;
  overflow: auto;
}

.left-modal {
  max-width: calc(97dvw);
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 98dvh;
  left: 1dvw;
  border-radius: 15px 15px 15px 15px;
}

.left-modal main {
  height: 100%;
  overflow: auto;
}

.bottom-modal main {
  max-height: 70dvh;
  overflow: auto;
  max-width: 100%;
}
.top-modal main {
  max-height: 70dvh;
  overflow: auto;
  max-width: 100%;
}

.b-modal main {
  overflow: auto;
}
</style>
