<script lang="ts" setup>
import MaterialSymbolsArrowForwardIosRounded from '~icons/material-symbols/arrow-forward-ios-rounded'
import ResourceImage from '@/components/resource/resourceImage.vue'
import BLabel from '@/components/common/bLabel.vue'

const props = defineProps<{
  project: {
    _id: string
    display: string
    client: {
      display: string
    }
    director: {
      _id: string
      firstname: string
      lastname: string
      picture: string
    }
  }
}>()
const emit = defineEmits<{
  openSearch: []
}>()
</script>

<template>
  <li
    class="group transition flex cursor-default items-center rounded-md p-2 group hover:bg-primary-600 hover:cursor-pointer"
    role="option" tabindex="-1"
  >
    <RouterLink
      :to="{ name: 'ProjectInformationPage', params: { projectId: project._id } }" class="w-full flex flex-wrap gap-4"
      @click="emit('openSearch')"
    >
      <div class="flex gap-1">
        <BLabel v-if="project.client" size="dense">
          {{ project.client.display }}
        </BLabel>
        <ResourceImage v-if="project.director" :resource="project.director" size="h-6 w-6" />
      </div>
      <p class="ml-3 flex-auto truncate group-hover:text-white">
        {{ project.display }}
      </p>
      <MaterialSymbolsArrowForwardIosRounded class="ml-3 h-5 w-5 flex-none group-hover:text-white" />
    </RouterLink>
  </li>
</template>
