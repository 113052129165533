import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, paginate } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'profiles'

const superAdminGetList = (axios: IFactoryFunctionRequest<Array<{ display: string }>, any>) => (query: { page: number; limit: number; display?: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/superAdminList`,
    query,
  })
}

const getAllowProfiles = (axios: IFactoryFunctionRequest<paginate<{ display: string }>, any>) => (query: { page: number; limit: number; search?: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/filter`,
    query,
  })
}

const superAdminGetOne = (axios: IFactoryFunctionRequest<{ display: string }, any>) => (profileId) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/superAdminGetEdit/${profileId}`,
  })
}

const superAdminUpdateOne = (axios: IFactoryFunctionRequest<any, any>) => (profileId, profile) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${profileId}`,
    data: profile,
  })
}
const importAccessRights = (axios: IFactoryFunctionRequest<any, any>) => ({ profileIds = [], allProfiles = false }) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/importAccessFromV1`,
    query: { allProfiles, profileIds },
  })
}

export default (axios: AxiosInstance) => ({
  superAdminGetList: superAdminGetList(useAxios(axios)),
  superAdminGetOne: superAdminGetOne(useAxios(axios)),
  superAdminUpdateOne: superAdminUpdateOne(useAxios(axios)),
  getAllowProfiles: getAllowProfiles(useAxios(axios)),
  importAccessRights: importAccessRights(useAxios(axios)),
})
