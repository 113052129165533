<script lang="ts" setup>
import { computed, ref } from 'vue'
import type { IResource } from '@bc-library/interface'
import DefaultProfile from '@/assets/svg/profile.svg'

type IResourceImageProps = {
  resource?: Pick<IResource, 'lastname' | 'firstname' | 'picture' | '_id'>
  width?: string
  height?: string
  size?: string
  shadow?: string
  popover?: boolean
  rounded?: string
  to?: {
    name: string
    params?: any
    query?: any
  }
  disableLink?: boolean
}

const props = withDefaults(defineProps<IResourceImageProps>(), {
  width: 'w-7',
  height: 'h-7',
  shadow: 'shadow',
  rounded: 'rounded-full',
  popover: true,
  disableLink: false,
})
const avatarSize = computed(() => {
  return props.size ? props.size : `${props.width} ${props.height}`
})

const onClick = (event) => {
  if (!props.disableLink)
    event.stopPropagation()
}

const imageError = ref(false)
</script>

<template>
  <component
    :is="disableLink ? 'div' : 'router-link'"
    v-if="resource" class="bg-transparent flex-shrink-0" :class="[avatarSize, popover ? 'cursor-help' : undefined]" :to="to ? to : { name: 'EmployeeProfile', params: { resourceId: resource._id } }"
    :title="!popover ? null : `${resource.firstname} ${resource.lastname}`"
    :aria-label="`Navigate to profile ${resource.firstname || ''} ${resource.lastname || ''}`"
    @click="onClick"
  >
    <img
      v-if="resource.picture && !imageError" width="15px" height="15px" loading="lazy" :src="resource.picture" :alt="`Avatar de ${resource.firstname} ${resource.lastname}`"
      class="shadow" :class="[avatarSize, shadow, rounded]"
      @error="() => imageError = true"
    >
    <DefaultProfile v-else :class="avatarSize" />
  </component>
</template>

<style>
</style>
