import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import { IResourceChildren } from './interfaces'

const resource = 'resourceChildren'

const getResource = (axios: IFactoryFunctionRequest<Array<any>, any>) => ( { resourceId }: { resourceId: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/resource/${resourceId}`,
  })
}

const createResourceChildren = (axios: IFactoryFunctionRequest<any, any>) => ({ children }: { children: IResourceChildren }) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/`,
    data: children,
  })
}

const updateResourceChildren = (axios: IFactoryFunctionRequest<any, any>) => ({ children }: {children: IResourceChildren}) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${children._id}/`,
    data: children,
  })
}

const deleteResourceChildren = (axios: IFactoryFunctionRequest<any, any>) => ({ idToDelete }: { idToDelete: string }) => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${idToDelete}/`,
  })
}

export default (axios: AxiosInstance) => ({
  getResource: getResource(useAxios(axios)),
  createResourceChildren: createResourceChildren(useAxios(axios)),
  updateResourceChildren: updateResourceChildren(useAxios(axios)),
  deleteResourceChildren: deleteResourceChildren(useAxios(axios)),
})
