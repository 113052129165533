import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse, ITrainingSession } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import type { IGetOneTrainingSession, INewTrainingSessionPage } from '@/services/trainingSession/trainingSessionInterface'

const resource = 'trainingSessions'

const getOne = (axios: IFactoryFunctionRequest<IGetOneTrainingSession, undefined>) => (trainingSessionId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${trainingSessionId}`,
  })
}
const getAll = (axios: IFactoryFunctionRequest) => (trainingId: string): IFactoryResponse<Array<ITrainingSession>> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/training/${trainingId}`,
  })
}

const updateOne = (axios: IFactoryFunctionRequest) => (training: ITrainingSession): IFactoryResponse<ITrainingSession> => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${training._id}`,
    data: { ...training },
  })
}

const create = (axios: IFactoryFunctionRequest<ITrainingSession, INewTrainingSessionPage>) => (trainingSession: INewTrainingSessionPage) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}`,
    data: trainingSession,
  })
}

const updateParticipant = (axios: IFactoryFunctionRequest<null, any>) => (payload: { participant: ITrainingSession['participants'][0]; sessionId: string }) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/participant/update/${payload.sessionId}/${payload.participant._id}`,
    data: payload.participant,
  })
}
const updateCheckItem = (axios: IFactoryFunctionRequest<null, any>) => (payload: { check: ITrainingSession['checklist'][0]; sessionId: string }) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/checklist/update/${payload.sessionId}/${payload.check._id}`,
    data: payload.check,
  })
}
const addCheckItem = (axios: IFactoryFunctionRequest<null, any>) => (payload: { check: Pick<ITrainingSession['checklist'][0], 'display'>; sessionId: string }) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/checklist/add/${payload.sessionId}`,
    data: payload.check,
  })
}

const sendReminder = (axios: IFactoryFunctionRequest<null, any>) => (payload: { participants: IGetOneTrainingSession['participants'] & {
  trainingSessionId: string
  display: string
  _firmId: string
  sender: {
    firstname: string
    lastname: string
    _id: string
  }
} }) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/sendReminder`,
    data: payload.participants,
  })
}

export default (axios: AxiosInstance) => ({
  getAll: getAll(useAxios(axios)),
  updateOne: updateOne(useAxios(axios)),
  create: create(useAxios(axios)),
  updateParticipant: updateParticipant(useAxios(axios)),
  getOne: getOne(useAxios(axios)),
  updateCheckItem: updateCheckItem(useAxios(axios)),
  addCheckItem: addCheckItem(useAxios(axios)),
  sendReminder: sendReminder(useAxios(axios)),
})
