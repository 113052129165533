import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, ISector } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'sectors'

const getFilter = (axios: IFactoryFunctionRequest<Array<Pick<ISector, '_id' | 'display'>>, undefined>) => () => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/filter`,
  })
}

export default (axios: AxiosInstance) => ({
  getFilter: getFilter(useAxios(axios)),
})
