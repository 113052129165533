import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse, ITrainingTrainer } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'trainingTrainers'

const getAll = (axios: IFactoryFunctionRequest) => ({ firmId }: { firmId: string }): IFactoryResponse<Array<ITrainingTrainer>> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/${firmId}`,
  })
}
const addOne = (axios: IFactoryFunctionRequest) => (trainer: Omit<ITrainingTrainer, '_id'>): IFactoryResponse<ITrainingTrainer> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}`,
    data: trainer,
  })
}

export default (axios: AxiosInstance) => ({
  getAll: getAll(useAxios(axios)),
  addOne: addOne(useAxios(axios)),
})
