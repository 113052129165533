import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, ITeam } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'howDidYouFindUs'

const filter = (axios: IFactoryFunctionRequest<Array<Pick<ITeam, '_id' | 'display'>>, undefined>) => ({
  page = 0,
  display = '',
  limit = 30,
}: { page?: number; display?: string; limit?: number }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/filter`,
    query: { page, display, limit },
  })
}

const create = (axios: IFactoryFunctionRequest<any, any>) => ({ display }) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/create`,
    data: { display },
  })
}

const update = (axios: IFactoryFunctionRequest<any, any>) => (id, { data }) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/update/${id}`,
    data,
  })
}

const deleteOne = (axios: IFactoryFunctionRequest<any, any>) => (id) => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/delete/${id}`,
  })
}

const retreiveAllPublic = (axios: IFactoryFunctionRequest<any, any>) => (firmId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/public/${firmId}`,
  })
}

export default (axios: AxiosInstance) => ({
  filter: filter(useAxios(axios)),
  create: create(useAxios(axios)),
  update: update(useAxios(axios)),
  deleteOne: deleteOne(useAxios(axios)),
  retreiveAllPublic: retreiveAllPublic(useAxios(axios)),
})
