import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'jobBoardFormTemplates'

const filter = (axios: IFactoryFunctionRequest) => ({ page = 0, search = '', limit = 10 }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/filter`,
    query: {
      page,
      search,
      limit,
    },
  })
}

const retreiveEditableForms = (axios: IFactoryFunctionRequest) => (itemId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/editableForms/${itemId}`,
  })
}
const retreiveFormToApply = (axios: IFactoryFunctionRequest) => (firmId: string, officeId: string, offerId?: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/retreiveFormToApply/${firmId}/${officeId}`,
    query: {
      offerId
    }
  })
}

const create = (axios: IFactoryFunctionRequest<any, any>) => (data) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/create`,
    data,
  })
}

const editForm = (axios: IFactoryFunctionRequest<any, any>) => ({ id, data }) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/editForm/${id}`,
    data,
  })
}

const deleteForm = (axios: IFactoryFunctionRequest<any, any>) => ({ id }) => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/deleteForm/${id}`,
  })
}



export default (axios: AxiosInstance) => ({
  filter: filter(useAxios(axios)),
  retreiveEditableForms: retreiveEditableForms(useAxios(axios)),
  create: create(useAxios(axios)),
  editForm: editForm(useAxios(axios)),
  deleteForm: deleteForm(useAxios(axios)),
  retreiveFormToApply: retreiveFormToApply(useAxios(axios)),
})
